import * as React from 'react';
import { useContext, useMemo } from 'react';
import classNames from 'classnames';
import { ConfigContext } from '../config-provider';
import { FormItemInputContext } from '../form/context';
const Group = props => {
    const { getPrefixCls, direction } = useContext(ConfigContext);
    const { prefixCls: customizePrefixCls, className = '' } = props;
    const prefixCls = getPrefixCls('input-group', customizePrefixCls);
    const cls = classNames(prefixCls, {
        [`${prefixCls}-lg`]: props.size === 'large',
        [`${prefixCls}-sm`]: props.size === 'small',
        [`${prefixCls}-compact`]: props.compact,
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, className);
    const formItemContext = useContext(FormItemInputContext);
    const groupFormItemContext = useMemo(() => (Object.assign(Object.assign({}, formItemContext), { isFormItemInput: false })), [formItemContext]);
    return (<span className={cls} style={props.style} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave} onFocus={props.onFocus} onBlur={props.onBlur}>
      <FormItemInputContext.Provider value={groupFormItemContext}>
        {props.children}
      </FormItemInputContext.Provider>
    </span>);
};
export default Group;
