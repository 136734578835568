var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import RcTooltip from 'rc-tooltip';
import useMergedState from 'rc-util/lib/hooks/useMergedState';
import classNames from 'classnames';
import getPlacements, { AdjustOverflow, PlacementsConfig } from '../_util/placements';
import { cloneElement, isValidElement } from '../_util/reactNode';
import { ConfigContext } from '../config-provider';
import { PresetColorTypes } from '../_util/colors';
import { getTransitionName } from '../_util/motion';
export { AdjustOverflow, PlacementsConfig };
const splitObject = (obj, keys) => {
    const picked = {};
    const omitted = Object.assign({}, obj);
    keys.forEach(key => {
        if (obj && key in obj) {
            picked[key] = obj[key];
            delete omitted[key];
        }
    });
    return { picked, omitted };
};
const PresetColorRegex = new RegExp(`^(${PresetColorTypes.join('|')})(-inverse)?$`);
// Fix Tooltip won't hide at disabled button
// mouse events don't trigger at disabled button in Chrome
// https://github.com/react-component/tooltip/issues/18
function getDisabledCompatibleChildren(element, prefixCls) {
    const elementType = element.type;
    if (((elementType.__ANT_BUTTON === true || element.type === 'button') && element.props.disabled) ||
        (elementType.__ANT_SWITCH === true && (element.props.disabled || element.props.loading))) {
        // Pick some layout related style properties up to span
        // Prevent layout bugs like https://github.com/ant-design/ant-design/issues/5254
        const { picked, omitted } = splitObject(element.props.style, [
            'position',
            'left',
            'right',
            'top',
            'bottom',
            'float',
            'display',
            'zIndex',
        ]);
        const spanStyle = Object.assign(Object.assign({ display: 'inline-block' }, picked), { cursor: 'not-allowed', width: element.props.block ? '100%' : null });
        const buttonStyle = Object.assign(Object.assign({}, omitted), { pointerEvents: 'none' });
        const child = cloneElement(element, {
            style: buttonStyle,
            className: null,
        });
        return (<span style={spanStyle} className={classNames(element.props.className, `${prefixCls}-disabled-compatible-wrapper`)}>
        {child}
      </span>);
    }
    return element;
}
const Tooltip = React.forwardRef((props, ref) => {
    const { getPopupContainer: getContextPopupContainer, getPrefixCls, direction, } = React.useContext(ConfigContext);
    const [visible, setVisible] = useMergedState(false, {
        value: props.visible,
        defaultValue: props.defaultVisible,
    });
    const isNoTitle = () => {
        const { title, overlay } = props;
        return !title && !overlay && title !== 0; // overlay for old version compatibility
    };
    const onVisibleChange = (vis) => {
        var _a;
        setVisible(isNoTitle() ? false : vis);
        if (!isNoTitle()) {
            (_a = props.onVisibleChange) === null || _a === void 0 ? void 0 : _a.call(props, vis);
        }
    };
    const getTooltipPlacements = () => {
        const { builtinPlacements, arrowPointAtCenter, autoAdjustOverflow } = props;
        return (builtinPlacements ||
            getPlacements({
                arrowPointAtCenter,
                autoAdjustOverflow,
            }));
    };
    // 动态设置动画点
    const onPopupAlign = (domNode, align) => {
        const placements = getTooltipPlacements();
        // 当前返回的位置
        const placement = Object.keys(placements).find(key => placements[key].points[0] === align.points[0] &&
            placements[key].points[1] === align.points[1]);
        if (!placement) {
            return;
        }
        // 根据当前坐标设置动画点
        const rect = domNode.getBoundingClientRect();
        const transformOrigin = {
            top: '50%',
            left: '50%',
        };
        if (placement.indexOf('top') >= 0 || placement.indexOf('Bottom') >= 0) {
            transformOrigin.top = `${rect.height - align.offset[1]}px`;
        }
        else if (placement.indexOf('Top') >= 0 || placement.indexOf('bottom') >= 0) {
            transformOrigin.top = `${-align.offset[1]}px`;
        }
        if (placement.indexOf('left') >= 0 || placement.indexOf('Right') >= 0) {
            transformOrigin.left = `${rect.width - align.offset[0]}px`;
        }
        else if (placement.indexOf('right') >= 0 || placement.indexOf('Left') >= 0) {
            transformOrigin.left = `${-align.offset[0]}px`;
        }
        domNode.style.transformOrigin = `${transformOrigin.left} ${transformOrigin.top}`;
    };
    const getOverlay = () => {
        const { title, overlay } = props;
        if (title === 0) {
            return title;
        }
        return overlay || title || '';
    };
    const { getPopupContainer } = props, otherProps = __rest(props, ["getPopupContainer"]);
    const { prefixCls: customizePrefixCls, openClassName, getTooltipContainer, overlayClassName, color, overlayInnerStyle, children, } = props;
    const prefixCls = getPrefixCls('tooltip', customizePrefixCls);
    const rootPrefixCls = getPrefixCls();
    let tempVisible = visible;
    // Hide tooltip when there is no title
    if (!('visible' in props) && isNoTitle()) {
        tempVisible = false;
    }
    const child = getDisabledCompatibleChildren(isValidElement(children) ? children : <span>{children}</span>, prefixCls);
    const childProps = child.props;
    const childCls = classNames(childProps.className, {
        [openClassName || `${prefixCls}-open`]: true,
    });
    const customOverlayClassName = classNames(overlayClassName, {
        [`${prefixCls}-rtl`]: direction === 'rtl',
        [`${prefixCls}-${color}`]: color && PresetColorRegex.test(color),
    });
    let formattedOverlayInnerStyle = overlayInnerStyle;
    let arrowContentStyle;
    if (color && !PresetColorRegex.test(color)) {
        formattedOverlayInnerStyle = Object.assign(Object.assign({}, overlayInnerStyle), { background: color });
        // @ts-ignore
        arrowContentStyle = { '--antd-arrow-background-color': color };
    }
    return (<RcTooltip {...otherProps} prefixCls={prefixCls} overlayClassName={customOverlayClassName} getTooltipContainer={getPopupContainer || getTooltipContainer || getContextPopupContainer} ref={ref} builtinPlacements={getTooltipPlacements()} overlay={getOverlay()} visible={tempVisible} onVisibleChange={onVisibleChange} onPopupAlign={onPopupAlign} overlayInnerStyle={formattedOverlayInnerStyle} arrowContent={<span className={`${prefixCls}-arrow-content`} style={arrowContentStyle}/>} motion={{
            motionName: getTransitionName(rootPrefixCls, 'zoom-big-fast', props.transitionName),
            motionDeadline: 1000,
        }}>
      {tempVisible ? cloneElement(child, { className: childCls }) : child}
    </RcTooltip>);
});
Tooltip.displayName = 'Tooltip';
Tooltip.defaultProps = {
    placement: 'top',
    mouseEnterDelay: 0.1,
    mouseLeaveDelay: 0.1,
    arrowPointAtCenter: false,
    autoAdjustOverflow: true,
};
export default Tooltip;
