var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import StarFilled from '@ant-design/icons/StarFilled';
import RcRate from 'rc-rate';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import Tooltip from '../tooltip';
const Rate = React.forwardRef((_a, ref) => {
    var { prefixCls, tooltips } = _a, props = __rest(_a, ["prefixCls", "tooltips"]);
    const characterRender = (node, { index }) => {
        if (!tooltips)
            return node;
        return <Tooltip title={tooltips[index]}>{node}</Tooltip>;
    };
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const ratePrefixCls = getPrefixCls('rate', prefixCls);
    return (<RcRate ref={ref} characterRender={characterRender} {...props} prefixCls={ratePrefixCls} direction={direction}/>);
});
Rate.displayName = 'Rate';
Rate.defaultProps = {
    character: <StarFilled />,
};
export default Rate;
