var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import RcSegmented from 'rc-segmented';
import { ConfigContext } from '../config-provider';
import SizeContext from '../config-provider/SizeContext';
function isSegmentedLabeledOptionWithIcon(option) {
    return typeof option === 'object' && !!(option === null || option === void 0 ? void 0 : option.icon);
}
const Segmented = React.forwardRef((props, ref) => {
    const { prefixCls: customizePrefixCls, className, block, options, size: customSize = 'middle' } = props, restProps = __rest(props, ["prefixCls", "className", "block", "options", "size"]);
    const { getPrefixCls, direction } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('segmented', customizePrefixCls);
    // ===================== Size =====================
    const size = React.useContext(SizeContext);
    const mergedSize = customSize || size;
    // syntactic sugar to support `icon` for Segmented Item
    const extendedOptions = React.useMemo(() => options.map(option => {
        if (isSegmentedLabeledOptionWithIcon(option)) {
            const { icon, label } = option, restOption = __rest(option, ["icon", "label"]);
            return Object.assign(Object.assign({}, restOption), { label: (<>
                <span className={`${prefixCls}-item-icon`}>{icon}</span>
                {label && <span>{label}</span>}
              </>) });
        }
        return option;
    }), [options, prefixCls]);
    return (<RcSegmented {...restProps} className={classNames(className, {
            [`${prefixCls}-block`]: block,
            [`${prefixCls}-sm`]: mergedSize === 'small',
            [`${prefixCls}-lg`]: mergedSize === 'large',
        })} options={extendedOptions} ref={ref} prefixCls={prefixCls} direction={direction}/>);
});
Segmented.displayName = 'Segmented';
Segmented.defaultProps = {
    options: [],
};
export default Segmented;
