import * as React from 'react';
import omit from 'rc-util/lib/omit';
import { FormProvider as RcFormProvider } from 'rc-field-form';
import { useMemo } from 'react';
export const FormContext = React.createContext({
    labelAlign: 'right',
    vertical: false,
    itemRef: (() => { }),
});
export const NoStyleItemContext = React.createContext(null);
export const FormProvider = props => {
    const providerProps = omit(props, ['prefixCls']);
    return <RcFormProvider {...providerProps}/>;
};
export const FormItemPrefixContext = React.createContext({
    prefixCls: '',
});
export const FormItemInputContext = React.createContext({});
export const NoFormStatus = ({ children }) => {
    const emptyContext = useMemo(() => ({}), []);
    return (<FormItemInputContext.Provider value={emptyContext}>{children}</FormItemInputContext.Provider>);
};
