var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import DoubleLeftOutlined from '@ant-design/icons/DoubleLeftOutlined';
import DoubleRightOutlined from '@ant-design/icons/DoubleRightOutlined';
import LeftOutlined from '@ant-design/icons/LeftOutlined';
import RightOutlined from '@ant-design/icons/RightOutlined';
import classNames from 'classnames';
import RcPagination, { PaginationLocale } from 'rc-pagination';
import enUS from 'rc-pagination/lib/locale/en_US';
import * as React from 'react';
import { ConfigContext } from '../config-provider';
import useBreakpoint from '../grid/hooks/useBreakpoint';
import LocaleReceiver from '../locale-provider/LocaleReceiver';
import { MiddleSelect, MiniSelect } from './Select';
export { PaginationLocale };
const Pagination = (_a) => {
    var { prefixCls: customizePrefixCls, selectPrefixCls: customizeSelectPrefixCls, className, size, locale: customLocale, selectComponentClass, responsive, showSizeChanger } = _a, restProps = __rest(_a, ["prefixCls", "selectPrefixCls", "className", "size", "locale", "selectComponentClass", "responsive", "showSizeChanger"]);
    const { xs } = useBreakpoint(responsive);
    const { getPrefixCls, direction, pagination = {} } = React.useContext(ConfigContext);
    const prefixCls = getPrefixCls('pagination', customizePrefixCls);
    const mergedShowSizeChanger = showSizeChanger !== null && showSizeChanger !== void 0 ? showSizeChanger : pagination.showSizeChanger;
    const getIconsProps = () => {
        const ellipsis = <span className={`${prefixCls}-item-ellipsis`}>•••</span>;
        let prevIcon = (<button className={`${prefixCls}-item-link`} type="button" tabIndex={-1}>
        <LeftOutlined />
      </button>);
        let nextIcon = (<button className={`${prefixCls}-item-link`} type="button" tabIndex={-1}>
        <RightOutlined />
      </button>);
        let jumpPrevIcon = (<a className={`${prefixCls}-item-link`}>
        {/* You can use transition effects in the container :) */}
        <div className={`${prefixCls}-item-container`}>
          <DoubleLeftOutlined className={`${prefixCls}-item-link-icon`}/>
          {ellipsis}
        </div>
      </a>);
        let jumpNextIcon = (<a className={`${prefixCls}-item-link`}>
        {/* You can use transition effects in the container :) */}
        <div className={`${prefixCls}-item-container`}>
          <DoubleRightOutlined className={`${prefixCls}-item-link-icon`}/>
          {ellipsis}
        </div>
      </a>);
        // change arrows direction in right-to-left direction
        if (direction === 'rtl') {
            [prevIcon, nextIcon] = [nextIcon, prevIcon];
            [jumpPrevIcon, jumpNextIcon] = [jumpNextIcon, jumpPrevIcon];
        }
        return {
            prevIcon,
            nextIcon,
            jumpPrevIcon,
            jumpNextIcon,
        };
    };
    const renderPagination = (contextLocale) => {
        const locale = Object.assign(Object.assign({}, contextLocale), customLocale);
        const isSmall = size === 'small' || !!(xs && !size && responsive);
        const selectPrefixCls = getPrefixCls('select', customizeSelectPrefixCls);
        const extendedClassName = classNames({
            [`${prefixCls}-mini`]: isSmall,
            [`${prefixCls}-rtl`]: direction === 'rtl',
        }, className);
        return (<RcPagination {...getIconsProps()} {...restProps} prefixCls={prefixCls} selectPrefixCls={selectPrefixCls} className={extendedClassName} selectComponentClass={selectComponentClass || (isSmall ? MiniSelect : MiddleSelect)} locale={locale} showSizeChanger={mergedShowSizeChanger}/>);
    };
    return (<LocaleReceiver componentName="Pagination" defaultLocale={enUS}>
      {renderPagination}
    </LocaleReceiver>);
};
export default Pagination;
