var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import Spin from '../spin';
import useBreakpoint from '../grid/hooks/useBreakpoint';
import { responsiveArray } from '../_util/responsiveObserve';
import { ConfigContext } from '../config-provider';
import Pagination from '../pagination';
import { Row } from '../grid';
import Item from './Item';
import defaultRenderEmpty from '../config-provider/defaultRenderEmpty';
export { ListItemProps, ListItemMetaProps } from './Item';
export const ListContext = React.createContext({});
export const ListConsumer = ListContext.Consumer;
function List(_a) {
    var { pagination = false, prefixCls: customizePrefixCls, bordered = false, split = true, className, children, itemLayout, loadMore, grid, dataSource = [], size, header, footer, loading = false, rowKey, renderItem, locale } = _a, rest = __rest(_a, ["pagination", "prefixCls", "bordered", "split", "className", "children", "itemLayout", "loadMore", "grid", "dataSource", "size", "header", "footer", "loading", "rowKey", "renderItem", "locale"]);
    const paginationObj = pagination && typeof pagination === 'object' ? pagination : {};
    const [paginationCurrent, setPaginationCurrent] = React.useState(paginationObj.defaultCurrent || 1);
    const [paginationSize, setPaginationSize] = React.useState(paginationObj.defaultPageSize || 10);
    const { getPrefixCls, renderEmpty, direction } = React.useContext(ConfigContext);
    const defaultPaginationProps = {
        current: 1,
        total: 0,
    };
    const listItemsKeys = {};
    const triggerPaginationEvent = (eventName) => (page, pageSize) => {
        setPaginationCurrent(page);
        setPaginationSize(pageSize);
        if (pagination && pagination[eventName]) {
            pagination[eventName](page, pageSize);
        }
    };
    const onPaginationChange = triggerPaginationEvent('onChange');
    const onPaginationShowSizeChange = triggerPaginationEvent('onShowSizeChange');
    const renderInnerItem = (item, index) => {
        if (!renderItem)
            return null;
        let key;
        if (typeof rowKey === 'function') {
            key = rowKey(item);
        }
        else if (rowKey) {
            key = item[rowKey];
        }
        else {
            key = item.key;
        }
        if (!key) {
            key = `list-item-${index}`;
        }
        listItemsKeys[index] = key;
        return renderItem(item, index);
    };
    const isSomethingAfterLastItem = () => !!(loadMore || pagination || footer);
    const renderEmptyFunc = (prefixCls, renderEmptyHandler) => (<div className={`${prefixCls}-empty-text`}>
      {(locale && locale.emptyText) || renderEmptyHandler('List')}
    </div>);
    const prefixCls = getPrefixCls('list', customizePrefixCls);
    let loadingProp = loading;
    if (typeof loadingProp === 'boolean') {
        loadingProp = {
            spinning: loadingProp,
        };
    }
    const isLoading = loadingProp && loadingProp.spinning;
    // large => lg
    // small => sm
    let sizeCls = '';
    switch (size) {
        case 'large':
            sizeCls = 'lg';
            break;
        case 'small':
            sizeCls = 'sm';
            break;
        default:
            break;
    }
    const classString = classNames(prefixCls, {
        [`${prefixCls}-vertical`]: itemLayout === 'vertical',
        [`${prefixCls}-${sizeCls}`]: sizeCls,
        [`${prefixCls}-split`]: split,
        [`${prefixCls}-bordered`]: bordered,
        [`${prefixCls}-loading`]: isLoading,
        [`${prefixCls}-grid`]: !!grid,
        [`${prefixCls}-something-after-last-item`]: isSomethingAfterLastItem(),
        [`${prefixCls}-rtl`]: direction === 'rtl',
    }, className);
    const paginationProps = Object.assign(Object.assign(Object.assign({}, defaultPaginationProps), { total: dataSource.length, current: paginationCurrent, pageSize: paginationSize }), (pagination || {}));
    const largestPage = Math.ceil(paginationProps.total / paginationProps.pageSize);
    if (paginationProps.current > largestPage) {
        paginationProps.current = largestPage;
    }
    const paginationContent = pagination ? (<div className={`${prefixCls}-pagination`}>
      <Pagination {...paginationProps} onChange={onPaginationChange} onShowSizeChange={onPaginationShowSizeChange}/>
    </div>) : null;
    let splitDataSource = [...dataSource];
    if (pagination) {
        if (dataSource.length > (paginationProps.current - 1) * paginationProps.pageSize) {
            splitDataSource = [...dataSource].splice((paginationProps.current - 1) * paginationProps.pageSize, paginationProps.pageSize);
        }
    }
    const needResponsive = Object.keys(grid || {}).some(key => ['xs', 'sm', 'md', 'lg', 'xl', 'xxl'].includes(key));
    const screens = useBreakpoint(needResponsive);
    const currentBreakpoint = React.useMemo(() => {
        for (let i = 0; i < responsiveArray.length; i += 1) {
            const breakpoint = responsiveArray[i];
            if (screens[breakpoint]) {
                return breakpoint;
            }
        }
        return undefined;
    }, [screens]);
    const colStyle = React.useMemo(() => {
        if (!grid) {
            return undefined;
        }
        const columnCount = currentBreakpoint && grid[currentBreakpoint] ? grid[currentBreakpoint] : grid.column;
        if (columnCount) {
            return {
                width: `${100 / columnCount}%`,
                maxWidth: `${100 / columnCount}%`,
            };
        }
    }, [grid === null || grid === void 0 ? void 0 : grid.column, currentBreakpoint]);
    let childrenContent = isLoading && <div style={{ minHeight: 53 }}/>;
    if (splitDataSource.length > 0) {
        const items = splitDataSource.map((item, index) => renderInnerItem(item, index));
        const childrenList = React.Children.map(items, (child, index) => (<div key={listItemsKeys[index]} style={colStyle}>
        {child}
      </div>));
        childrenContent = grid ? (<Row gutter={grid.gutter}>{childrenList}</Row>) : (<ul className={`${prefixCls}-items`}>{items}</ul>);
    }
    else if (!children && !isLoading) {
        childrenContent = renderEmptyFunc(prefixCls, renderEmpty || defaultRenderEmpty);
    }
    const paginationPosition = paginationProps.position || 'bottom';
    const contextValue = React.useMemo(() => ({ grid, itemLayout }), [JSON.stringify(grid), itemLayout]);
    return (<ListContext.Provider value={contextValue}>
      <div className={classString} {...rest}>
        {(paginationPosition === 'top' || paginationPosition === 'both') && paginationContent}
        {header && <div className={`${prefixCls}-header`}>{header}</div>}
        <Spin {...loadingProp}>
          {childrenContent}
          {children}
        </Spin>
        {footer && <div className={`${prefixCls}-footer`}>{footer}</div>}
        {loadMore ||
            ((paginationPosition === 'bottom' || paginationPosition === 'both') && paginationContent)}
      </div>
    </ListContext.Provider>);
}
List.Item = Item;
export default List;
