import * as React from 'react';
import usePatchElement from '../../_util/hooks/usePatchElement';
import HookModal from './HookModal';
import { withConfirm, withInfo, withSuccess, withError, withWarn } from '../confirm';
let uuid = 0;
const ElementsHolder = React.memo(React.forwardRef((_props, ref) => {
    const [elements, patchElement] = usePatchElement();
    React.useImperativeHandle(ref, () => ({
        patchElement,
    }), []);
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{elements}</>;
}));
export default function useModal() {
    const holderRef = React.useRef(null);
    // ========================== Effect ==========================
    const [actionQueue, setActionQueue] = React.useState([]);
    React.useEffect(() => {
        if (actionQueue.length) {
            const cloneQueue = [...actionQueue];
            cloneQueue.forEach(action => {
                action();
            });
            setActionQueue([]);
        }
    }, [actionQueue]);
    // =========================== Hook ===========================
    const getConfirmFunc = React.useCallback((withFunc) => function hookConfirm(config) {
        var _a;
        uuid += 1;
        const modalRef = React.createRef();
        let closeFunc;
        const modal = (<HookModal key={`modal-${uuid}`} config={withFunc(config)} ref={modalRef} afterClose={() => {
                closeFunc();
            }}/>);
        closeFunc = (_a = holderRef.current) === null || _a === void 0 ? void 0 : _a.patchElement(modal);
        return {
            destroy: () => {
                function destroyAction() {
                    var _a;
                    (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.destroy();
                }
                if (modalRef.current) {
                    destroyAction();
                }
                else {
                    setActionQueue(prev => [...prev, destroyAction]);
                }
            },
            update: (newConfig) => {
                function updateAction() {
                    var _a;
                    (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.update(newConfig);
                }
                if (modalRef.current) {
                    updateAction();
                }
                else {
                    setActionQueue(prev => [...prev, updateAction]);
                }
            },
        };
    }, []);
    const fns = React.useMemo(() => ({
        info: getConfirmFunc(withInfo),
        success: getConfirmFunc(withSuccess),
        error: getConfirmFunc(withError),
        warning: getConfirmFunc(withWarn),
        confirm: getConfirmFunc(withConfirm),
    }), []);
    // eslint-disable-next-line react/jsx-key
    return [fns, <ElementsHolder ref={holderRef}/>];
}
