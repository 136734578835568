import * as React from 'react';
import classNames from 'classnames';
import { tuple } from '../_util/type';
import Pagination from '../pagination';
import ListItem from './ListItem';
export const OmitProps = tuple('handleFilter', 'handleClear', 'checkedKeys');
function parsePagination(pagination) {
    if (!pagination) {
        return null;
    }
    const defaultPagination = {
        pageSize: 10,
        simple: true,
        showSizeChanger: false,
        showLessItems: false,
    };
    if (typeof pagination === 'object') {
        return Object.assign(Object.assign({}, defaultPagination), pagination);
    }
    return defaultPagination;
}
class ListBody extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            current: 1,
        };
        this.onItemSelect = (item) => {
            const { onItemSelect, selectedKeys } = this.props;
            const checked = selectedKeys.indexOf(item.key) >= 0;
            onItemSelect(item.key, !checked);
        };
        this.onItemRemove = (item) => {
            const { onItemRemove } = this.props;
            onItemRemove === null || onItemRemove === void 0 ? void 0 : onItemRemove([item.key]);
        };
        this.onPageChange = (current) => {
            this.setState({ current });
        };
        this.getItems = () => {
            const { current } = this.state;
            const { pagination, filteredRenderItems } = this.props;
            const mergedPagination = parsePagination(pagination);
            let displayItems = filteredRenderItems;
            if (mergedPagination) {
                displayItems = filteredRenderItems.slice((current - 1) * mergedPagination.pageSize, current * mergedPagination.pageSize);
            }
            return displayItems;
        };
    }
    static getDerivedStateFromProps({ filteredRenderItems, pagination }, { current }) {
        const mergedPagination = parsePagination(pagination);
        if (mergedPagination) {
            // Calculate the page number
            const maxPageCount = Math.ceil(filteredRenderItems.length / mergedPagination.pageSize);
            if (current > maxPageCount) {
                return { current: maxPageCount };
            }
        }
        return null;
    }
    render() {
        const { current } = this.state;
        const { prefixCls, onScroll, filteredRenderItems, selectedKeys, disabled: globalDisabled, showRemove, pagination, } = this.props;
        const mergedPagination = parsePagination(pagination);
        let paginationNode = null;
        if (mergedPagination) {
            paginationNode = (<Pagination simple={mergedPagination.simple} showSizeChanger={mergedPagination.showSizeChanger} showLessItems={mergedPagination.showLessItems} size="small" disabled={globalDisabled} className={`${prefixCls}-pagination`} total={filteredRenderItems.length} pageSize={mergedPagination.pageSize} current={current} onChange={this.onPageChange}/>);
        }
        return (<>
        <ul className={classNames(`${prefixCls}-content`, {
                [`${prefixCls}-content-show-remove`]: showRemove,
            })} onScroll={onScroll}>
          {this.getItems().map(({ renderedEl, renderedText, item }) => {
                const { disabled } = item;
                const checked = selectedKeys.indexOf(item.key) >= 0;
                return (<ListItem disabled={globalDisabled || disabled} key={item.key} item={item} renderedText={renderedText} renderedEl={renderedEl} checked={checked} prefixCls={prefixCls} onClick={this.onItemSelect} onRemove={this.onItemRemove} showRemove={showRemove}/>);
            })}
        </ul>

        {paginationNode}
      </>);
    }
}
export default ListBody;
