var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { forwardRef, useContext, useImperativeHandle } from 'react';
import classNames from 'classnames';
import CalendarOutlined from '@ant-design/icons/CalendarOutlined';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import SwapRightOutlined from '@ant-design/icons/SwapRightOutlined';
import { RangePicker as RCRangePicker } from 'rc-picker';
import enUS from '../locale/en_US';
import { ConfigContext } from '../../config-provider';
import SizeContext from '../../config-provider/SizeContext';
import DisabledContext from '../../config-provider/DisabledContext';
import LocaleReceiver from '../../locale-provider/LocaleReceiver';
import { getRangePlaceholder, transPlacement2DropdownAlign } from '../util';
import { Components, getTimeProps } from '.';
import { FormItemInputContext } from '../../form/context';
import { getMergedStatus, getStatusClassNames } from '../../_util/statusUtils';
export default function generateRangePicker(generateConfig) {
    const RangePicker = forwardRef((props, ref) => {
        const { prefixCls: customizePrefixCls, getPopupContainer: customGetPopupContainer, className, placement, size: customizeSize, disabled: customDisabled, bordered = true, placeholder, status: customStatus } = props, restProps = __rest(props, ["prefixCls", "getPopupContainer", "className", "placement", "size", "disabled", "bordered", "placeholder", "status"]);
        const innerRef = React.useRef(null);
        const { getPrefixCls, direction, getPopupContainer } = useContext(ConfigContext);
        const prefixCls = getPrefixCls('picker', customizePrefixCls);
        const { format, showTime, picker } = props;
        const rootPrefixCls = getPrefixCls();
        let additionalOverrideProps = {};
        additionalOverrideProps = Object.assign(Object.assign(Object.assign({}, additionalOverrideProps), (showTime ? getTimeProps(Object.assign({ format, picker }, showTime)) : {})), (picker === 'time' ? getTimeProps(Object.assign(Object.assign({ format }, props), { picker })) : {}));
        // ===================== Size =====================
        const size = React.useContext(SizeContext);
        const mergedSize = customizeSize || size;
        // ===================== Disabled =====================
        const disabled = React.useContext(DisabledContext);
        const mergedDisabled = customDisabled || disabled;
        // ===================== FormItemInput =====================
        const formItemContext = useContext(FormItemInputContext);
        const { hasFeedback, status: contextStatus, feedbackIcon } = formItemContext;
        const suffixNode = (<>
        {picker === 'time' ? <ClockCircleOutlined /> : <CalendarOutlined />}
        {hasFeedback && feedbackIcon}
      </>);
        useImperativeHandle(ref, () => ({
            focus: () => { var _a; return (_a = innerRef.current) === null || _a === void 0 ? void 0 : _a.focus(); },
            blur: () => { var _a; return (_a = innerRef.current) === null || _a === void 0 ? void 0 : _a.blur(); },
        }));
        return (<LocaleReceiver componentName="DatePicker" defaultLocale={enUS}>
        {(contextLocale) => {
                const locale = Object.assign(Object.assign({}, contextLocale), props.locale);
                return (<RCRangePicker separator={<span aria-label="to" className={`${prefixCls}-separator`}>
                  <SwapRightOutlined />
                </span>} disabled={mergedDisabled} ref={innerRef} dropdownAlign={transPlacement2DropdownAlign(direction, placement)} placeholder={getRangePlaceholder(picker, locale, placeholder)} suffixIcon={suffixNode} clearIcon={<CloseCircleFilled />} prevIcon={<span className={`${prefixCls}-prev-icon`}/>} nextIcon={<span className={`${prefixCls}-next-icon`}/>} superPrevIcon={<span className={`${prefixCls}-super-prev-icon`}/>} superNextIcon={<span className={`${prefixCls}-super-next-icon`}/>} allowClear transitionName={`${rootPrefixCls}-slide-up`} {...restProps} {...additionalOverrideProps} className={classNames({
                        [`${prefixCls}-${mergedSize}`]: mergedSize,
                        [`${prefixCls}-borderless`]: !bordered,
                    }, getStatusClassNames(prefixCls, getMergedStatus(contextStatus, customStatus), hasFeedback), className)} locale={locale.lang} prefixCls={prefixCls} getPopupContainer={customGetPopupContainer || getPopupContainer} generateConfig={generateConfig} components={Components} direction={direction}/>);
            }}
      </LocaleReceiver>);
    });
    return RangePicker;
}
