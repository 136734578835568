var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import classNames from 'classnames';
import CalendarOutlined from '@ant-design/icons/CalendarOutlined';
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import CloseCircleFilled from '@ant-design/icons/CloseCircleFilled';
import RCPicker from 'rc-picker';
import { forwardRef, useContext, useImperativeHandle } from 'react';
import enUS from '../locale/en_US';
import { getPlaceholder, transPlacement2DropdownAlign } from '../util';
import warning from '../../_util/warning';
import { ConfigContext } from '../../config-provider';
import LocaleReceiver from '../../locale-provider/LocaleReceiver';
import SizeContext from '../../config-provider/SizeContext';
import DisabledContext from '../../config-provider/DisabledContext';
import { getTimeProps, Components } from '.';
import { FormItemInputContext } from '../../form/context';
import { getMergedStatus, getStatusClassNames } from '../../_util/statusUtils';
export default function generatePicker(generateConfig) {
    function getPicker(picker, displayName) {
        const Picker = forwardRef((props, ref) => {
            const { prefixCls: customizePrefixCls, getPopupContainer: customizeGetPopupContainer, className, size: customizeSize, bordered = true, placement, placeholder, disabled: customDisabled, status: customStatus } = props, restProps = __rest(props, ["prefixCls", "getPopupContainer", "className", "size", "bordered", "placement", "placeholder", "disabled", "status"]);
            warning(picker !== 'quarter', displayName, `DatePicker.${displayName} is legacy usage. Please use DatePicker[picker='${picker}'] directly.`);
            const { getPrefixCls, direction, getPopupContainer } = useContext(ConfigContext);
            const prefixCls = getPrefixCls('picker', customizePrefixCls);
            const innerRef = React.useRef(null);
            const { format, showTime } = props;
            useImperativeHandle(ref, () => ({
                focus: () => { var _a; return (_a = innerRef.current) === null || _a === void 0 ? void 0 : _a.focus(); },
                blur: () => { var _a; return (_a = innerRef.current) === null || _a === void 0 ? void 0 : _a.blur(); },
            }));
            const additionalProps = {
                showToday: true,
            };
            let additionalOverrideProps = {};
            if (picker) {
                additionalOverrideProps.picker = picker;
            }
            const mergedPicker = picker || props.picker;
            additionalOverrideProps = Object.assign(Object.assign(Object.assign({}, additionalOverrideProps), (showTime ? getTimeProps(Object.assign({ format, picker: mergedPicker }, showTime)) : {})), (mergedPicker === 'time'
                ? getTimeProps(Object.assign(Object.assign({ format }, props), { picker: mergedPicker }))
                : {}));
            const rootPrefixCls = getPrefixCls();
            // ===================== Size =====================
            const size = React.useContext(SizeContext);
            const mergedSize = customizeSize || size;
            // ===================== Disabled =====================
            const disabled = React.useContext(DisabledContext);
            const mergedDisabled = customDisabled || disabled;
            // ===================== FormItemInput =====================
            const formItemContext = useContext(FormItemInputContext);
            const { hasFeedback, status: contextStatus, feedbackIcon } = formItemContext;
            const suffixNode = (<>
            {mergedPicker === 'time' ? <ClockCircleOutlined /> : <CalendarOutlined />}
            {hasFeedback && feedbackIcon}
          </>);
            return (<LocaleReceiver componentName="DatePicker" defaultLocale={enUS}>
            {(contextLocale) => {
                    const locale = Object.assign(Object.assign({}, contextLocale), props.locale);
                    return (<RCPicker ref={innerRef} placeholder={getPlaceholder(mergedPicker, locale, placeholder)} suffixIcon={suffixNode} dropdownAlign={transPlacement2DropdownAlign(direction, placement)} clearIcon={<CloseCircleFilled />} prevIcon={<span className={`${prefixCls}-prev-icon`}/>} nextIcon={<span className={`${prefixCls}-next-icon`}/>} superPrevIcon={<span className={`${prefixCls}-super-prev-icon`}/>} superNextIcon={<span className={`${prefixCls}-super-next-icon`}/>} allowClear transitionName={`${rootPrefixCls}-slide-up`} {...additionalProps} {...restProps} {...additionalOverrideProps} locale={locale.lang} className={classNames({
                            [`${prefixCls}-${mergedSize}`]: mergedSize,
                            [`${prefixCls}-borderless`]: !bordered,
                        }, getStatusClassNames(prefixCls, getMergedStatus(contextStatus, customStatus), hasFeedback), className)} prefixCls={prefixCls} getPopupContainer={customizeGetPopupContainer || getPopupContainer} generateConfig={generateConfig} components={Components} direction={direction} disabled={mergedDisabled}/>);
                }}
          </LocaleReceiver>);
        });
        if (displayName) {
            Picker.displayName = displayName;
        }
        return Picker;
    }
    const DatePicker = getPicker();
    const WeekPicker = getPicker('week', 'WeekPicker');
    const MonthPicker = getPicker('month', 'MonthPicker');
    const YearPicker = getPicker('year', 'YearPicker');
    const TimePicker = getPicker('time', 'TimePicker');
    const QuarterPicker = getPicker('quarter', 'QuarterPicker');
    return { DatePicker, WeekPicker, MonthPicker, YearPicker, TimePicker, QuarterPicker };
}
